<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Pharmacy Upload History - {{ pharmacy.name }}
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <Accordion>
                <AccordionTab header="New Upload">
                  <div class="flex-column">
                    <!-- Data Source -->
                    <div class="flex-input flex-item">
                      <label for="data_source">Data Source</label>
                      <Dropdown
                        id="data_source"
                        v-model="dataSource"
                        :options="etlDispensingSources"
                        option-label="name"
                        option-value="key"
                        placeholder="Select a Data Source"
                        class="grey-input"
                      />
                    </div>
                    <!-- ADVANCED OPTIONS -->
                    <div style="text-align: left;">
                      <Button
                        label="Advanced Options"
                        severity="warning"
                        outlined
                        @click="advancedOptions = !advancedOptions"
                      />
                    </div>
                    <div
                      v-if="advancedOptions"
                      class="advanced-options flex-column"
                    >
                      <!-- INITIAL UPLOAD -->
                      <div
                        class="flex-input flex-item"
                        style="align-self: flex-start;"
                      >
                        <label for="initial_upload">Initial Upload</label>
                        <SelectButton
                          id="initial_upload"
                          v-model="initialUpload"
                          :options="initialUploadOptions"
                          option-label="value"
                          option-value="key"
                        />
                      </div>
                      <div class="flex-row">
                        <!-- FROM TIME -->
                        <div class="flex-input flex-item">
                          <label for="from_time">From Time (UTC)</label>
                          <Calendar
                            id="from_time"
                            v-model="from"
                            show-time
                            show-button-bar
                            hour-format="24"
                            date-format="yy-mm-dd"
                            placeholder="Select From Date Time"
                            class="grey-input"
                          />
                        </div>
                        <!-- TO TIME -->
                        <div class="flex-input flex-item">
                          <label for="to_time">To Time (UTC)</label>
                          <Calendar
                            id="to_time"
                            v-model="to"
                            show-time
                            show-button-bar
                            hour-format="24"
                            date-format="yy-mm-dd"
                            placeholder="Select To Date Time"
                            class="grey-input"
                          />
                        </div>
                      </div>
                    </div>
                    <Button
                      label="Submit"
                      :loading="loadingUpload"
                      @click="requestUpload"
                    />
                    <p
                      v-if="requestUploadError"
                      class="error-message"
                    >
                      {{ requestUploadError }}
                    </p>
                    <p
                      v-if="requestUploadSuccess"
                      class="success-message"
                    >
                      {{ requestUploadSuccess }}
                    </p>
                  </div>
                </AccordionTab>
              </Accordion>
              <!-- TABLE FOR UPLOAD HISTORY -->
              <DataTable
                v-model:expandedRows="expandedQueueRows"
                :loading="loadingEtlQueue"
                :value="queueLocalLazy"
                scrollable
                scroll-height="44vh"
                :virtual-scroller-options="{ lazy: true, onLazyLoad:loadQueueLazy, itemSize: 75, delay: 0, showLoader: true, loading: lazyLoading, numToleratedItems: 10 }"
                data-key="uuid"
              >
                <template #empty>
                  No records found
                </template>
                <template #loading>
                  Loading records, please wait...
                </template>
                <template #header>
                  <div class="flex-row">
                    <span
                      class="flex-item"
                      style="text-align: left;"
                    >Upload History</span>
                    <Button
                      icon="pi pi-refresh"
                      :loading="loadingEtlQueue"
                      rounded
                      raised
                      @click="refresh"
                    />
                  </div>
                </template>
                <!-- EXPANDER -->
                <Column
                  expander
                  style="width: 5rem"
                />
                <!-- UPLOAD TIME -->
                <Column header="Upload Time">
                  <template #body="slotProps">
                    {{ $d(new Date(slotProps.data.location_upload_time), 'long') }}
                  </template>
                </Column>
                <!-- DATA SOURCE -->
                <Column
                  field="data_source"
                  header="Data Source"
                />
                <!-- STATE -->
                <Column header="ETL State">
                  <template #body="slotProps">
                    <Tag
                      :value="slotProps.data.etl_state"
                      :severity="getUploadSeverity(slotProps.data.etl_state)"
                    />
                  </template>
                </Column>
                <!-- DELETE BUTTON -->
                <!-- DELETES ALL THE FILES IN THE GROUP -->
                <Column>
                  <template #body="slotProps">
                    <Button
                      v-if="slotProps.data.etl_state.toLowerCase() === 'override' || slotProps.data.etl_state.toLowerCase() === 'received'"
                      icon="pi pi-trash"
                      outlined
                      rounded
                      severity="danger"
                      @click="openConfirmQueueDeletion(slotProps.data)"
                    />
                    <span v-else></span>
                  </template>
                </Column>
                <!-- EXPANDABLE SECTION -->
                <template #expansion="slotProps">
                  <div>
                    <!-- USER LOCATIONS TABLE -->
                    <div>
                      <div class="form-row">
                        <div class="flex-input flex-item">
                          <label for="etl_state">ETL State</label>
                          <InputText
                            id="etl_state"
                            v-model="slotProps.data.etl_state"
                            disabled
                            class="grey-input"
                          />
                        </div>
                        <div class="flex-input flex-item">
                          <label for="etl_completed">ETL Completed</label>
                          <InputText
                            id="etl_completed"
                            v-model="slotProps.data.etl_completed"
                            disabled
                            class="grey-input"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="flex-input flex-item">
                          <label for="start_time">Start Time</label>
                          <InputText
                            id="start_time"
                            v-model="slotProps.data.etl_start_time"
                            disabled
                            class="grey-input"
                          />
                        </div>
                        <div class="flex-input flex-item">
                          <label for="stop_time">Stop Time</label>
                          <InputText
                            id="stop_time"
                            v-model="slotProps.data.etl_stop_time"
                            disabled
                            class="grey-input"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="flex-input flex-item">
                          <label for="upload_time">Upload Time</label>
                          <InputText
                            id="upload_time"
                            v-model="slotProps.data.location_upload_time"
                            disabled
                            class="grey-input"
                          />
                        </div>
                        <div class="flex-input flex-item">
                          <label for="last_updated">Last Updated</label>
                          <InputText
                            id="last_updated"
                            v-model="slotProps.data.last_updated"
                            disabled
                            class="grey-input"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="flex-input flex-item">
                          <label for="server_timestamp">Server Timestamp</label>
                          <InputText
                            id="server_timestamp"
                            v-model="slotProps.data.server_timestamp"
                            disabled
                            class="grey-input"
                          />
                        </div>
                        <div class="flex-input flex-item">
                          <label for="timestamp">Timestamp</label>
                          <InputText
                            id="timestamp"
                            v-model="slotProps.data.timestamp"
                            disabled
                            class="grey-input"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="flex-input flex-item">
                          <label for="initial_upload">Initial Upload</label>
                          <InputText
                            id="initial_upload"
                            v-model="slotProps.data.initial_upload"
                            disabled
                            class="grey-input"
                          />
                        </div>
                        <div class="flex-input flex-item">
                          <label for="support_initiated">Support Initiated</label>
                          <InputText
                            id="support_initiated"
                            v-model="slotProps.data.support_initiated"
                            disabled
                            class="grey-input"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="flex-input flex-item">
                          <label for="data_source">Data Source</label>
                          <InputText
                            id="date_source"
                            v-model="slotProps.data.data_source"
                            disabled
                            class="grey-input"
                          />
                        </div>
                        <div class="flex-input flex-item">
                          <label for="uuid">UUID</label>
                          <InputText
                            id="uuid"
                            v-model="slotProps.data.uuid"
                            disabled
                            class="grey-input"
                          />
                        </div>
                      </div>
                      <div class="flex-input">
                        <label for="notes">Notes</label>
                        <Textarea
                          id="notes"
                          v-model="slotProps.data.notes"
                          disabled
                          rows="5"
                          class="grey-input"
                        />
                      </div>
                      <div class="flex-input">
                        <label>Files</label>
                        <DataTable
                          :value="slotProps.data.files"
                          scroll-height="250px"
                        >
                          <Column
                            field="file_type"
                            header="Type"
                          />
                          <Column
                            field="id"
                            header="ID"
                          />
                          <Column
                            field="file_path"
                            header="Path"
                          />
                          <!-- DELETE BUTTON -->
                          <!-- DELETES THE SPECIFIC FILE -->
                          <Column>
                            <template #body="fileSlotProps">
                              <Button
                                v-if="slotProps.data.etl_state.toLowerCase() === 'override' || slotProps.data.etl_state.toLowerCase() === 'received'"
                                icon="pi pi-trash"
                                outlined
                                rounded
                                severity="danger"
                                @click="openConfirmFileDeletion(slotProps.data, fileSlotProps.data)"
                              />
                            </template>
                          </Column>
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </template>
              </DataTable>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
  <ConfirmDialog group="location_etl_confirmation" />
</template>
  
<script>
import axios from 'axios';
import { useConfirm } from 'primevue/useconfirm';

export default {
    props: {
        /**
         * The selected pharmacy
         * @type {Object}
         */
        pharmacy: {
            type: Object,
            default() {
                return {};
            }
        },
        /**
         * The flag to perform a data refresh when the modal is displayed initially - useful in support dashboard overview
         * @type {Object}
         */
        initialRefresh: {
          type: Boolean,
          default() { 
            return false;
          }
        }
    },
    /**
     * Emits events to parent component
     */
    emits: [
        'updateQueue',
        'closeModal'
    ],
    data() {
        /**
         * The local data for the component
         */
        return {
          advancedOptions: false,
          expandedQueueRows: [],
          initialUploadOptions: [
            {
              value: 'Yes',
              key: true
            },
            {
              value: 'No',
              key: false
            }
          ],
          dataSource: null,
          from: null,
          to: null,
          initialUpload: null,
          loadingUpload: false,
          requestUploadError: '',
          requestUploadSuccess: '',
          loadingEtlQueue: false,
          etlQueueError: '',
          queueLocal: [],
          confirm: useConfirm(),
          queueLocalLazy: [],
          lazyLoading: false,
          loadLazyTimeout: null
        };
    },
    computed: {
      requestUploadPayload() {
        return {
          id: this.pharmacy.uuid,
          data_source: this.dataSource,
          from: this.from ? new Date(this.from).toISOString() : null,
          to: this.to ? new Date(this.to).toISOString() : null,
          initial_upload: this.initialUpload
        }
      },
      etlDispensingSources() {
        return this.dispensingSources.concat({
          name: 'Strong ER',
          key: 'stronger'
        });
      }
    },
    mounted() {
      this.dataSource = this.pharmacy.data_source;
      // If the initialRefresh props is set to true, load the ETL Queue upon display
      if (this.initialRefresh) {
        this.queueLocal = [];
        this.refresh();
      }
      else {
        this.queueLocal = this.pharmacy.queue_items;
        this.queueLocalLazy = this.queueLocal;
      }
    },
    methods: {
        /**
         * Emit the close modal event
         */
        closeModal() {
            this.$emit('closeModal');
        },
        /**
         * Request a new upload
         * @return {Promise} - The promise of the support/request-pharmacy-upload request
         */
        async requestUpload() {
          this.$store.dispatch('getRefreshToken');
          this.requestUploadError = '';
          this.requestUploadSuccess = '';
          this.loadingUpload = true;

          await axios.post('/api/support/request-pharmacy-upload', this.requestUploadPayload, this.$store.state.header)
          .then(res => {
            this.handleRequestUploadSuccess(res);
            this.refresh();
          })
          .catch(err => {
            this.handleRequestUploadError(this.handleAxiosError(err));
          })
        },
        /**
         * Handle the request upload success case
         * @param {Object} res
         */
        handleRequestUploadSuccess(res) {
          console.log('The request upload response is', res.data);
          this.loadingUpload = false;
          this.requestUploadSuccess = 'Upload successfully requested';
        },
        /**
         * Handle the request upload error case
         * @param {Object} err
         */
        handleRequestUploadError(err) {
          console.log('The request upload error is', err);
          this.loadingUpload = false;
          this.requestUploadError = err;
        },
        /**
         * Refresh the etlQueue for the pharmacy
         * @return {Promise} - The promise of the support/view-etl-queue-items request
         */
        async refresh() {
          this.$store.dispatch('getRefreshToken');
          this.etlQueueError = '';
          this.loadingEtlQueue = true;

          await axios.post('/api/support/view-etl-queue-items', { id: [this.pharmacy.uuid] }, this.$store.state.header)
          .then(res => {
            this.handleRefreshSuccess(res);
          })
          .catch(err => {
            this.handleRefreshError(this.handleAxiosError(err));
          })
        },
        /**
         * Handle the refresh success case
         * @param {Object} res
         */
        handleRefreshSuccess(res) {
          console.log('The refresh response is', res.data);
          // Find the location in the response data
          const locationIndex = res.data.findIndex(l => l.pharmacy === this.pharmacy.uuid);

          if (locationIndex === -1) {
            this.handleRefreshError('The location was not found in the response data');
            return;
          }

          // Update the queueLocal with the new data
          this.queueLocal = res.data[locationIndex].queue;
          // Update the lazy queue
          this.queueLocalLazy = this.queueLocal;
          this.loadingEtlQueue = false;

          // Emit the updated queue to the parent page
          this.$emit("updateQueue", {
            queue: this.queueLocal
          });
        },
        /**
         * Handle the refresh error case
         * @param {Object} err
         */
        handleRefreshError(err) {
          console.log('The refresh queue error is', err);
          this.loadingEtlQueue = false;
          this.etlQueueError = err;
        },
        /**
         * Load the queue items lazily
         * @param {Object} event - The event object
         */
        loadQueueLazy(event) {
          !this.lazyLoading && (this.lazyLoading = true);

          let _virtualQueue = [...this.queueLocalLazy];
          let { first, last } = event;

          //load data of required page, in future we'll change this to pagination with API
          const loadedQueue = this.queueLocal.slice(first, last);

          //populate page of virtual cars
          Array.prototype.splice.apply(_virtualQueue, [...[first, last - first], ...loadedQueue]);

          this.queueLocalLazy = _virtualQueue;
          this.lazyLoading = false;
        },
        /**
         * Open the confirm queue deletion dialog
         * @param {Object} queue - The queue item to remove
         */
        openConfirmQueueDeletion(queue) {
          this.$store.dispatch('getRefreshToken');
          this.confirm.require({
            header: 'Confirm Queue Item Deletion',
            message: 'Are you sure you want to remove this queue item?',
            icon: 'pi pi-exclamation-triangle',
            group: 'location_etl_confirmation',
            /**
             * If the user confirms the deletion
             * Remove the queue item
             */
            accept: () => {
              this.removeQueueItem(queue);
            },
            reject: () => {
              console.log('The queue item removal was cancelled');
            }
          })
        },
        /**
         * Remove the queue item
         * @param {Object} queue - The queue item to remove
         */
         async removeQueueItem(queue) {
          this.$store.dispatch('getRefreshToken');
          this.etlQueueError = '';
          this.loadingEtlQueue = true;

          await axios.post('/api/support/delete-etl-queue-items', { id: this.pharmacy.uuid, uuid: [queue.uuid] }, this.$store.state.header)
          .then(res => {
            this.handleRemoveQueueItemSuccess(res);
          })
          .catch(err => {
            this.handleRemoveQueueItemError(this.handleAxiosError(err));
          })
        },
        /**
         * Handle the success response for removing the queue item
         * @param {Object} res - The response object
         */
        handleRemoveQueueItemSuccess(res) {
          console.log('The remove queue item response is:', res.data);

          // Remove the items from queue returned in response
          this.queueLocal = this.queueLocal.filter(q => !res.data.includes(q.uuid));

          this.loadingEtlQueue = false;
          // Emit the updated queue to the parent page
          this.$emit("updateQueue", {
            queue: this.queueLocal
          });
        },
        /**
         * Handle the error response for removing the queue item
         * @param {Object} err - The error object
         */
        handleRemoveQueueItemError(err) {
          console.log('The remove queue item error is: ', err);
          this.loadingEtlQueue = false;
        },
        /**
         * Open the confirm queue deletion dialog
         * @param {Object} queue - The queue item selected
         * @param {Object} file - The file to remove
         */
        openConfirmFileDeletion(queue, file) {
          this.$store.dispatch('getRefreshToken');
          this.confirm.require({
            header: 'Confirm File Deletion',
            message: 'Are you sure you want to remove this file?',
            icon: 'pi pi-exclamation-triangle',
            group: 'location_etl_confirmation',
            /**
             * If the user confirms the deletion
             * Remove the file item
             */
            accept: () => {
              this.removeFileItem(queue, file);
            },
            reject: () => {
              console.log('The file item removal was cancelled');
            }
          })
        },
        /**
         * Remove the file item
         * @param {Object} queue - The queue item to selected
         * @param {Object} file - The file item to remove
         */
         async removeFileItem(queue, file) {
          this.$store.dispatch('getRefreshToken');
          this.etlQueueError = '';
          this.loadingEtlQueue = true;

          await axios.post('/api/support/delete-etl-queue-items', { id: this.pharmacy.uuid, uuid: [file.id] }, this.$store.state.header)
          .then(res => {
            this.handleRemoveFileItemSuccess(queue, res);
          })
          .catch(err => {
            this.handleRemoveFileItemError(this.handleAxiosError(err));
          })
        },
        /**
         * Handle the success response for removing the file item
         * @param {Object} queue - The queue item to selected
         * @param {Object} res - The response object
         */
        handleRemoveFileItemSuccess(queue, res) {
          console.log('The remove file item response is:', res.data);

          // Remove the items from queue returned in response
          this.queueLocal = this.queueLocal.filter(q => !res.data.includes(q.uuid));

          this.loadingEtlQueue = false;
          // Emit the updated queue to the parent page
          this.$emit("updateQueue", {
            queue: this.queueLocal
          });
        },
        /**
         * Handle the error response for removing the file item
         * @param {Object} err - The error object
         */
        handleRemoveFileItemError(err) {
          console.log('The remove queue item error is: ', err);
          this.loadingEtlQueue = false;
        },
        /**
         * Get the upload severity
         * @param {String} status 
         */
        getUploadSeverity(status) {
          switch (status) {
            case 'RECEIVED':
            case 'OVERRIDE':
            case 'REQUESTED':
              return 'info';
            case 'NOT RECEIVED':
              return 'warning';
            case 'PROCESSING':
              return 'help';
            case 'ERROR':
              return 'danger';
            case 'COMPLETED':
              return 'success';
            default:
              return 'secondary';
          }
        },
    }
}
</script>
  
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.advanced-options {
  border: 1px dashed grey;
  padding: 10px;
}

.modal-container {
  display: flex;
  flex-direction: column;
  height: 78vh;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.p-inputtext,
.p-selectbutton,
.p-password-input,
.p-togglebutton {
  border-radius: 5px;
  border: 1px solid $phoneorders;
  overflow: hidden;
}

.p-selectbutton .p-button .p-password-input {
  border: none !important;
}

:deep(.p-selectbutton) {
  display: flex;
}

:deep(.p-selectbutton) div {
  flex: 1;
}

.flex-item {
  flex: 1;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

</style>
