import { createApp } from 'vue';
// I18n import
import { createI18n} from 'vue-i18n';
// Sentry import
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
// Splunk Import
import SplunkOtelWeb from '@splunk/otel-web';
// Google Places Autocomplete
import VueGoogleAutocomplete from "vue-google-autocomplete";
// Userback
import UserbackPlugin from '@userback/vue';
// Primevue import
import PrimeVue from 'primevue/config';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import Calendar from 'primevue/calendar';
import Chip from 'primevue/chip';
import Tag from 'primevue/tag';
import MultiSelect from 'primevue/multiselect';
import Tooltip from 'primevue/tooltip';
import Skeleton from 'primevue/skeleton';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Divider from 'primevue/divider';
import ProgressSpinner from 'primevue/progressspinner';
import InputNumber from 'primevue/inputnumber';
import InputMask from 'primevue/inputmask';
import Textarea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Password from 'primevue/password';
import InputSwitch from 'primevue/inputswitch';
import SelectButton from 'primevue/selectbutton';
import ToggleButton from 'primevue/togglebutton';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import Card from 'primevue/card';                   //optional for card view
import "primevue/resources/themes/saga-blue/theme.css"       //theme
import "primevue/resources/primevue.min.css"                 //core css
import "primeicons/primeicons.css"                           //icons
// Charting import
import VueApexCharts from "vue3-apexcharts";
// Axios import, router, store import
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import router from './router';
import { store } from './store';

const i18n = createI18n({
  // if environment variable is set, use it, otherwise default to 'AU'
  locale: 'en-' + process.env.VUE_APP_COUNTRY_CODE,
  fallbackLocale: 'en-AU',
  messages: {
    'en-AU': {
      message: {
        hello: 'AU Version'
      },
      currencySymbol: '$',
      currencyTextSingular: 'dollar',
      currencyTextPlural: 'dollars',
      currencyFractionSymbol: 'c',
      distanceUnit: 'km',
      dateFormat: 'dd/mm/yy',
      dateRangeFormat: 'dd/mm/yyyy - dd/mm/yyyy'
    },
    'en-GB': {
      message: {
        hello: 'UK Version'
      },
      currencySymbol: '£',
      currencyTextSingular: 'pound',
      currencyTextPlural: 'pounds',
      currencyFractionSymbol: 'p',
      distanceUnit: 'mi',
      dateFormat: 'dd/mm/yy',
      dateRangeFormat: 'dd/mm/yyyy - dd/mm/yyyy'
    },
    'en-US': {
      message: {
        hello: 'US Version'
      },
      currencySymbol: '$',
      currencyTextSingular: 'dollar',
      currencyTextPlural: 'dollars',
      currencyFractionSymbol: 'c',
      distanceUnit: 'mi',
      dateFormat: 'mm/dd/yy',
      dateRangeFormat: 'mm/dd/yyyy - mm/dd/yyyy'
    }
  },
  datetimeFormats: {
    'en-AU': {
      short: {
        year: '2-digit', month: '2-digit', day: '2-digit'
      },
    },
    'en-GB': {
      short: {
        year: '2-digit', month: '2-digit', day: '2-digit'
      },
    },
    'en-US': {
      short: {
        year: '2-digit', month: '2-digit', day: '2-digit'
      },
    }
  },
  numberFormats: {
      'en-AU': {
        currency: {
          style: 'currency', currency: 'AUD', useGrouping: true, currencyDisplay: 'symbol', notation: 'compact', compactDisplay: 'short'
        },
        decimal: {
          style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
          style: 'percent', useGrouping: false, notation: 'standard'
        },
        integer: {
          style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0
        }
      },
      'en-GB': {
        currency: {
          style: 'currency', currency: 'GBP', useGrouping: true, currencyDisplay: 'symbol', notation: 'compact', compactDisplay: 'short'
        },
        decimal: {
          style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
          style: 'percent', useGrouping: false, notation: 'standard'
        },
        integer: {
          style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0
        }
      },
      'en-US': {
        currency: {
          style: 'currency', currency: 'USD', useGrouping: true, currencyDisplay: 'symbol', notation: 'compact', compactDisplay: 'short'
        },
        decimal: {
          style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
        percent: {
          style: 'percent', useGrouping: false, notation: 'standard'
        },
        integer: {
          style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0
        }
      }
  },
  legacy: false,
  globalInjection: true
});

const app = createApp(App)
  .use(router)
  .use(store)
  .use(VueAxios, axios)
  .use(PrimeVue)
  .use(VueApexCharts)
  .use(ConfirmationService)
  .use(UserbackPlugin, {
    token: process.env.VUE_APP_USERBACK_TOKEN
  })
  .use(i18n)
  .component('VueGoogleAutocomplete', VueGoogleAutocomplete)
  .component('ConfirmDialog', ConfirmDialog)
  .component('Calendar', Calendar)
  .component('RadioButton', RadioButton)
  .component('Button', Button)
  .component('Menu', Menu)
  .component('Menubar', Menubar)
  .component('ProgressSpinner', ProgressSpinner)
  .component('Password', Password)
  .component('ToggleButton', ToggleButton)
  .component('SelectButton', SelectButton)
  .component('Checkbox', Checkbox)
  .component('Dropdown', Dropdown)
  .component('InputSwitch', InputSwitch)
  .component('MultiSelect', MultiSelect)
  .component('Skeleton', Skeleton)
  .component('InputText', InputText)
  .component('InputNumber', InputNumber)
  .component('InputMask', InputMask)
  .component('TreeSelect', TreeSelect)
  .component('TreeTable', TreeTable)
  .component('TabView', TabView)
  .component('TabPanel', TabPanel)
  .component('Accordion', Accordion)
  .component('AccordionTab', AccordionTab)
  .component('Textarea', Textarea)
  .component('DataTable', DataTable)
  .component('Column', Column)
  .component('ColumnGroup', ColumnGroup)
  .component('Row', Row)
  .component('Card', Card)
  .directive('tooltip', Tooltip)
  .component('Chip', Chip)
  .component('Tag', Tag)
  .component('Divider', Divider)
  .mixin({
    data: function() {
      return {
        get states() {
          // If the country code is GB, only show the UK states
          if (process.env.VUE_APP_COUNTRY_CODE === 'GB') {
            return [
              'England',
              'Scotland',
              'Wales',
              'Northern Ireland'
            ]
          }
             
          // Default to AU states
          return [
            'ACT',
            'NSW',
            'NT',
            'QLD',
            'SA',
            'TAS',
            'VIC',
            'WA'
          ]
          
        },
        get dispensingSources() {
          // If the country code is GB, only show Z Dispense and Emis
          if (process.env.VUE_APP_COUNTRY_CODE === 'GB') {
            return [
              {
                name: 'Z Dispense',
                key: 'z'
              },
              {
                name: 'Emis',
                key: 'emis'
              }
            ]
          }
          // Default to AU dispensing sources
          return [
            {
              name: 'Fred Dispense Classic',
              key: 'fred'
            },
            {
              name: 'Fred Dispense Plus',
              key: 'frednxt'
            },
            {
              name: 'Z Dispense',
              key: 'z'
            },
            {
              name: 'Minfos',
              key: 'minfos'
            },
            {
              name: 'Dispense Works',
              key: 'posworks'
            },
            {
              name: 'RxOne',
              key: 'rxone'
            },
            {
              name: 'Corum LOTS',
              key: 'corumlots'
            },
            {
              name: 'Corum Clear',
              key: 'corumclear'
            }
          ]
        },
        get deploymentEnvironment() {
          return process.env.VUE_APP_ENVIRONMENT;
        },
        get countryCode() {
          return process.env.VUE_APP_COUNTRY_CODE;
        }
      }
    },
    methods: {
      handleAxiosError(error) {
        let responseMessage = '';
  
        // If the request was made and a non 2xx response was returned
        if (error.response) {
          console.log('The error data is: ', error.response.data);
          console.log('The error status is: ', error.response.status);
          console.log('The error headers are: ', error.response.headers);
          
          // Get the message from the laravel response if it exists
          let response = error.response.data;
          if (response && response.message) {
            responseMessage = response.message;
            // Loop through the laravel errors and add them to the response message
            if (response.errors) {
              Object.keys(response.errors).forEach(err => {
                response.errors[err].forEach(errorMessage => {
                  responseMessage += ' ' + errorMessage;
                })
              })
            }
          } else if (response) {
            responseMessage = response;
          } else {
            responseMessage = error;
          }
        }
        // If the request was made but no response was returned
        else if (error.request) {
          console.log('The error request is: ', error.request);
          responseMessage = error.request;
        }
        // If something else happened while setting up the request
        else {
          console.log('The error message is: ', error.message);
          responseMessage = error;
        }
        
        // If the token is expired
        if (responseMessage && typeof responseMessage === 'string' && responseMessage.split(' ').includes('expired')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }

        return responseMessage;
      },
      setSplunkUserSession(session_id) {
        console.log('setting session_id to: ', session_id);
        SplunkOtelWeb.setGlobalAttributes({
          'enduser.session_id': session_id
        })
      }
    }
  })

app.config.errorHandler = function (error, vm, info) {
  // To avoid loading issues due to content blockers
  // when using the CDN version of the Browser RUM
  // agent, add if (window.SplunkRum) checks around
  // SplunkRum API calls
  SplunkOtelWeb.error(error, info)
}
app.mount('#app');

Sentry.init({
  App,
  dsn: 'https://6d42b35f96864384a3553abadfc5bbd4@o981937.ingest.sentry.io/4504642940174336',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', 'my-site-url.com', /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

SplunkOtelWeb.init({
  realm: 'au0',
  rumAccessToken: process.env.VUE_APP_RUM_ACCESS_TOKEN,
  applicationName: 'enterprise-vue',
  deploymentEnvironment: process.env.VUE_APP_ENVIRONMENT
});
