<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Create Account
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <Accordion>
                <!-- COMPANY TAB -->
                <AccordionTab>
                  <!-- HEADER -->
                  <template #header>
                    <span>Company</span>
                    <span
                      v-if="companySelected"
                      style="padding-left: 20px; color: green;"
                    >&#9989;</span>
                  </template>
                  <!-- SELECT EXISTING COMPANY -->
                  <p>
                    Select Existing Company:
                  </p>
                  <Dropdown
                    v-model="selectedCompany"
                    :loading="loadingCompanies"
                    :editable="true"
                    :options="companies"
                    option-label="name"
                    placeholder="Select a Company"
                    class="grey-input"
                    @keyup="debounceSearchCompanies(selectedCompany)"
                  />
                  <!-- CREATE NEW COMPANY -->
                  <p>
                    Or Create New Company:
                  </p>
                  <InputText
                    v-model="addCompanyPayload.company_name"
                    placeholder="Enter a Company Name"
                    class="grey-input"
                  />
                  <div class="form-row">
                    <InputText
                      v-model="addCompanyPayload.username"
                      placeholder="Enter a Username"
                      class="grey-input flex-item"
                    />
                    <InputText
                      v-model="addCompanyPayload.email"
                      placeholder="Enter a Email"
                      class="grey-input flex-item"
                    />
                  </div>
                  <div class="form-row">
                    <InputText
                      v-model="addCompanyPayload.first_name"
                      placeholder="Enter a First Name"
                      class="grey-input flex-item"
                    />
                    <InputText
                      v-model="addCompanyPayload.last_name"
                      placeholder="Select a Last Name"
                      class="grey-input flex-item"
                    />
                  </div>
                  <InputText
                    v-model="addCompanyPayload.password"
                    placeholder="Enter a Password"
                    class="grey-input"
                    type="password"
                  />
                  <div class="flex-input flex-item">
                    <label for="sixty_day_signup">60 Day Signup</label>
                    <Checkbox
                      v-model="addCompanyPayload.sixty_day_signup"
                      input-id="sixty_day_signup" 
                      :binary="true"
                    />
                  </div>
                  <div class="flex-input flex-item">
                    <label for="cloud_ecdr">Cloud eCDR</label>
                    <Checkbox
                      v-model="addCompanyPayload.cloud_ecdr"
                      input-id="cloud_ecdr" 
                      :binary="true"
                    />
                  </div>
                  <!-- CREATE COMPANY BUTTON AND RESPONSE MESSAGES -->
                  <div>
                    <Button
                      type="button"
                      label="Create Company"
                      :loading="creatingCompany"
                      @click="addCompany"
                    />
                    <p
                      v-if="createCompanyError"
                      class="error-message"
                    >
                      {{ createCompanyError }}
                    </p>
                    <p
                      v-if="createCompanySuccess"
                      class="success-message"
                    >
                      {{ createCompanySuccess }}
                    </p>
                  </div>
                </AccordionTab>
                <!-- USERS TAB -->
                <AccordionTab>
                  <!-- HEADER -->
                  <template #header>
                    <span>Users</span>
                    <span
                      v-if="users.length > 0"
                      style="padding-left: 20px; color: green;"
                    >&#9989;</span>
                  </template>
                  <!-- CURRENT USERS -->
                  <p>
                    Current Users:
                  </p>
                  <!-- USERS TABLE -->
                  <DataTable
                    :value="users"
                    :loading="loadingUsers"
                    data-key="uuid"
                  >
                    <template #empty>
                      No users
                    </template>
                    <template #loading>
                      Loading records, please wait...
                    </template>
                    <Column header="Full Name">
                      <template #body="slotProps">
                        <span v-if="slotProps.data.person && slotProps.data.person.personal_information">
                          {{ [slotProps.data.person.personal_information.first_name, slotProps.data.person.personal_information.middle_name, slotProps.data.person.personal_information.last_name].filter(Boolean).join(' ') }}
                        </span>
                      </template>
                    </Column>
                    <Column
                      header="Username"
                      field="name"
                    />
                    <Column
                      header="Email"
                      field="email"
                    />
                  </DataTable>
                  <!-- ADD USER -->
                  <p>
                    Add User:
                  </p>
                  <div class="form-row">
                    <InputText
                      v-model="addUserForm.username"
                      placeholder="Enter a Username"
                      class="grey-input flex-item"
                    />
                    <InputText
                      v-model="addUserForm.password"
                      placeholder="Enter a Password"
                      class="grey-input flex-item"
                      type="password"
                    />
                  </div>
                  <div class="form-row">
                    <InputText
                      v-model="addUserForm.first_name"
                      placeholder="Enter a First Name"
                      class="grey-input flex-item"
                    />
                    <InputText
                      v-model="addUserForm.last_name"
                      placeholder="Select a Last Name"
                      class="grey-input flex-item"
                    />
                  </div>
                  <div class="form-row">
                    <InputText
                      v-model="addUserForm.email"
                      placeholder="Enter an Email"
                      class="grey-input flex-item"
                    />
                  </div>
                  <div class="form-row">
                    <label for="account_creation_email">Send Account Creation Email</label>
                    <Checkbox
                      v-model="addUserForm.account_creation_email"
                      input-id="account_creation_email"
                      :binary="true"
                    />
                  </div>
                  <!-- ADD USER BUTTON AND RESPONSE MESSAGES -->
                  <div>
                    <Button
                      type="button"
                      label="Create User"
                      :loading="creatingUser"
                      :disabled="!companySelected"
                      @click="addUser"
                    />
                    <p
                      v-if="createUserError"
                      class="error-message"
                    >
                      {{ createUserError }}
                    </p>
                    <p
                      v-if="createUserSuccess"
                      class="success-message"
                    >
                      {{ createUserSuccess }}
                    </p>
                  </div>
                </AccordionTab>
                <!-- LOCATIONS TAB -->
                <AccordionTab>
                  <!-- HEADER -->
                  <template #header>
                    <span>Locations</span>
                    <span
                      v-if="pharmacies.length > 0"
                      style="padding-left: 20px; color: green;"
                    >&#9989;</span>
                  </template>
                  <!-- ADD LOCATION -->
                  <p>
                    Add Location:
                  </p>
                  <div class="form-row">
                    <InputText
                      v-model="addPharmacyForm.name"
                      placeholder="Enter a Pharmacy Name"
                      class="grey-input flex-item"
                    />
                    <Dropdown
                      v-model="addPharmacyForm.owner"
                      placeholder="Select a Pharmacy Owner"
                      :options="users"
                      :loading="loadingUsers"
                      class="flex-item"
                      option-value="uuid"
                      option-label="name"
                    />
                  </div>
                  <div class="form-row">
                    <InputText
                      v-model="addPharmacyForm.phone_number"
                      placeholder="Pharmacy Phone"
                      class="grey-input flex-item"
                    />
                    <vue-google-autocomplete
                      v-if="$store.getters.getMapsApiLoaded == true"
                      id="map"
                      classname="p-inputtext p-component grey-input flex-item"
                      placeholder="Pharmacy Address"
                      :country="countryCode.toLowerCase()"
                      :enable-geolocation="true"
                      @placechanged="getAddressData"
                    />
                    <InputText
                      v-else
                      v-model="addPharmacyForm.street_address"
                      placeholder="Enter a Street Address"
                      class="grey-input flex-item"
                    />
                  </div>
                  <div class="form-row">
                    <InputText
                      v-model="addPharmacyForm.town"
                      :placeholder="townPlaceholder"
                      class="grey-input flex-item"
                    />
                    <InputText
                      v-model="addPharmacyForm.postcode"
                      placeholder="Enter a Postcode"
                      class="grey-input flex-item"
                      :type="countryCode === 'AU' ? 'number' : 'text'"
                    />
                  </div>
                  <div class="form-row">
                    <Dropdown
                      v-if="countryCode === 'AU'"
                      v-model="addPharmacyForm.region"
                      placeholder="Select a State"
                      :options="states"
                      class="flex-item"
                    />
                    <InputText
                      v-model="addPharmacyForm.country_name"
                      placeholder="Enter a Country"
                      class="grey-input flex-item"
                    />
                  </div>
                  <Dropdown
                    v-model="addPharmacyForm.data_source"
                    :options="dispensingSources"
                    option-label="name"
                    option-value="key"
                    placeholder="Select a Data Source"
                    class="grey-input"
                  />
                  <div class="form-row">
                    <MultiSelect
                      v-model="selectedModules"
                      :options="pharmacyModules"
                      option-label="display_name"
                      placeholder="Select Modules"
                      class="grey-input flex-item"
                    />
                    <InputText
                      v-if="pharmaProgramsSelectedModule"
                      v-model="pharmaProgramsSelectedModule.external_id"
                      placeholder="PharmaPrograms ID"
                      class="grey-input flex-item"
                    />
                  </div>
                  <!-- 
                    The no_guild_consent value is a boolean
                    The checkbox is checked if the value is true
                  -->
                  <div class="flex-input flex-item">
                    <label for="no_guild_consent">No Guild Consent</label>
                    <Checkbox
                      v-model="addPharmacyForm.no_guild_consent"
                      input-id="no_guild_consent"
                      :binary="true"
                    />
                  </div>
                  <!-- CREATE LOCATION BUTTON AND RESPONSE MESSAGES -->
                  <div>
                    <Button
                      type="button"
                      label="Create Pharmacy"
                      :loading="creatingPharmacy"
                      :disabled="!companySelected"
                      @click="addPharmacy"
                    />
                    <p
                      v-if="createPharmacyError"
                      class="error-message"
                    >
                      {{ createPharmacyError }}
                    </p>
                    <p
                      v-if="createPharmacySuccess"
                      class="success-message"
                    >
                      {{ createPharmacySuccess }}
                    </p>
                  </div>
                </AccordionTab>
                <!-- LINK USERS TAB -->
                <AccordionTab>
                  <!-- HEADER -->
                  <template #header>
                    <span>Link Users</span>
                  </template>
                  <div class="form-row">
                    <p>
                      Link Users:
                    </p>
                    <Button
                      type="button"
                      label="Select All Locations"
                      size="small"
                      @click="linkAllLoginLocations"
                    />
                    <Button
                      type="button"
                      label="Unselect All Locations"
                      size="small"
                      @click="unlinkAllLoginLocations"
                    />
                  </div>
                  
                  <DataTable
                    :value="loginLocations"
                    :loading="loadingLocations || loadingUsers || loadingLoginLocations"
                    data-key="uuid"
                  >
                    <template #empty>
                      No records found
                    </template>
                    <template #loading>
                      Loading records, please wait...
                    </template>
                    <Column header="Full Name">
                      <template #body="slotProps">
                        <span v-if="slotProps.data.person && slotProps.data.person.personal_information">
                          {{ [slotProps.data.person.personal_information.first_name, slotProps.data.person.personal_information.middle_name, slotProps.data.person.personal_information.last_name].filter(Boolean).join(' ') }}
                        </span>
                      </template>
                    </Column>
                    <Column
                      header="Username"
                      field="name"
                    />
                    <Column header="Locations">
                      <template #body="slotProps">
                        <div
                          v-for="pharmacy in pharmacies" 
                          :key="pharmacy.uuid"
                        >
                          <Checkbox
                            v-model="selectedLoginLocations[slotProps.data.uuid]"
                            :input-id="pharmacy.uuid"
                            :value="pharmacy.uuid"
                            name="location"
                          />
                          <label
                            :for="pharmacy.uuid"
                            style="font-size: 9pt;"
                          >
                            {{ pharmacy.name }}
                          </label>
                        </div>
                      </template>
                    </Column>
                  </DataTable>
                  <!-- LINK LOCATION BUTTON AND RESPONSE MESSAGES -->
                  <div>
                    <Button
                      type="button"
                      label="Link Locations"
                      :loading="linkingLocations"
                      @click="linkLocations"
                    />
                    <p
                      v-if="linkLocationsError"
                      class="error-message"
                    >
                      {{ linkLocationsError }}
                    </p>
                    <p
                      v-if="linkLocationsSuccess"
                      class="success-message"
                    >
                      {{ linkLocationsSuccess }}
                    </p>
                  </div>
                </AccordionTab>
                <!-- ONBOARDING TAB -->
                <AccordionTab>
                  <!-- HEADER -->
                  <template #header>
                    <span>Onboarding</span>
                  </template>
                  <div class="form-row">
                    <p>
                      StrongPro Onboarding:
                    </p>
                    <Button
                      type="button"
                      label="Select All"
                      size="small"
                      @click="selectAllWelcomeEmail"
                    />
                    <Button
                      type="button"
                      label="Select All Unsent"
                      size="small"
                      @click="selectUnsentWelcomeEmail"
                    />
                    <Button
                      type="button"
                      label="Unselect All"
                      size="small"
                      @click="deselectAllWelcomeEmail"
                    />
                  </div>
                  
                  <DataTable
                    :value="onboardingNotifications"
                    :loading="loadingLocations || loadingUsers || loadingLoginLocations || loadingOnboardingNotifications"
                  >
                    <template #empty>
                      No records found
                    </template>
                    <template #loading>
                      Loading records, please wait...
                    </template>
                    <Column header="Full Name">
                      <template #body="slotProps">
                        <span>
                          <!-- RETRIEVE THE FULL NAME OF USER FROM THEIR UUID -->
                          {{ fullNameFromUuid(slotProps.data.login_uuid) }}
                        </span>
                      </template>
                    </Column>
                    <Column
                      header="Username"
                      field="login_name"
                    />
                    <Column
                      header="Email Address"
                      field="email"
                    />
                    <Column
                      header="Location"
                      field="location_name"
                    />
                    <Column header="Received">
                      <template #body="slotProps">
                        <div
                          v-if="slotProps.data.created_at !== null"
                          class="flex-column"
                        >
                          <span>Email Address: {{ slotProps.data.destination }}</span>
                          <span>Received Date: {{ $d(new Date(slotProps.data.created_at), 'short') }}</span>
                        </div>
                        <div v-else>
                          <span class="error-message">Not Received</span>
                        </div>
                      </template>
                    </Column>
                    <Column header="Send Email">
                      <template #body="slotProps">
                        <Checkbox
                          v-model="slotProps.data.selected"
                          binary
                        />
                      </template>
                    </Column>
                  </DataTable>
                  <!-- SEND EMAIL BUTTON AND RESPONSE MESSAGES -->
                  <div>
                    <Button
                      type="button"
                      label="Send Welcome Email"
                      :loading="sendingWelcomeEmail"
                      @click="sendStrongProWelcomeEmail"
                    />
                    <p
                      v-if="sendWelcomeEmailError"
                      class="error-message"
                    >
                      {{ sendWelcomeEmailError }}
                    </p>
                    <p
                      v-if="sendWelcomeEmailSuccess"
                      class="success-message"
                    >
                      {{ sendWelcomeEmailSuccess }}
                    </p>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  props: {
    /**
     * The modules to be displayed in the modules dropdown
     * @type {Array}
     */
    modules: {
      type: Array,
      default() {
        return []
      }
    }
  },
  /**
   * Emits events to parent component
   */
  emits: [
    'closeModal'
  ],
  data() {
    /**
     * The local data for the component
     */
    return {
      creatingCompany: false,
      selectedCompany: null,
      companies: [],
      loadingCompanies: false,
      timeout: null,
      createCompanyError: '',
      createCompanySuccess: '',
      creatingPharmacy: false,
      pharmacies: [],
      createPharmacyError: '',
      createPharmacySuccess: '',
      users: [],
      loadingUsers: false,
      creatingUser: false,
      createUserError: '',
      createUserSuccess: '',
      loadingLocations: false,
      linkingLocations: false,
      linkLocationsError: '',
      linkLocationsSuccess: '',
      loadingLoginLocations: false,
      loadingOnboardingNotifications: false,
      onboardingNotificationsError: '',
      onboardingNotificationsSuccess: '',
      onboardingNotifications: [],
      sendingWelcomeEmail: false,
      sendWelcomeEmailError: '',
      sendWelcomeEmailSuccess: '',
      addCompanyPayload: {
        company_name: '',
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        sixty_day_signup: false,
        cloud_ecdr: false
      },
      loginLocations: [],
      selectedLoginLocations: [],
      addPharmacyForm: {
        name: '',
        phone_number: '',
        street_address: '',
        city: '',
        region: '',
        postcode: '',
        country_name: '',
        town: '',
        data_source: '',
        no_guild_consent: false,
        owner: ''
      },
      addUserForm: {
        username: '',
        password: '',
        first_name: '',
        last_name: '',
        email: '',
        account_creation_email: true
      },
      selectedModules: [],
      modulesLocal: []
    }
  },
  computed: {
    /**
     * If a company is selected
     * @returns {Boolean}
     */
    companySelected() {
      return typeof this.selectedCompany === 'object' && this.selectedCompany !== null;
    },
    /**
     * The Add Pharmacy Payload
     * @returns {Object}
     */
    addPharmacyPayload() {
      return {
        ...this.addPharmacyForm,
        company_id: this.selectedCompany ? this.selectedCompany.uuid : null,
        module_ids: this.selectedModules.map((module) => ({
          module_id: module.uuid,
          external_id: module.external_id ? module.external_id : null
        }))
      }
    },
    /**
     * The Add User Payload
     * @returns {Object}
     */
    addUserPayload() {
      return {
        ...this.addUserForm,
        company_id: this.selectedCompany ? this.selectedCompany.uuid : null
      }
    },
    /**
     * The StrongPro Welcome Email Payload
     * @returns {Object}
     */
    strongProWelcomeEmailPayload() {
      return {
        login_locations: this.onboardingNotifications.filter(notification => notification.selected).map(({login_uuid, location_uuid}) => ({
          location_id: location_uuid,
          login_id: login_uuid
        }))
      }
    },
    /**
     * Get the country name
     * @returns {String}
     */
     getCountryName() {
        if (this.countryCode === 'GB') {
          return 'United Kingdom';
        }
        else {
          return 'Australia';
        }
      },
    /**
     * The town placeholder
     * town for GB and suburb for AU
     * @returns {String}
     */
      townPlaceholder() {
      if (this.countryCode === 'GB') {
        return 'Enter a Town';
      }
      else {
        return 'Enter a Suburb';
      }
    },
    /**
     * The modules which are for pharmacy
     * @return {Array}
     */
    pharmacyModules() {
      return this.modulesLocal.filter(module => module.location_type === 'pharmacy');
    },
    pharmaProgramsSelectedModule() {
      return this.selectedModules.find(module => module.name == 'pharmaprograms');
    },
  },
  watch: {
    /**
     * Watch for changes to the users or pharmacies
     */
    users() {
      this.updateLoginLocations();
    },
    pharmacies() {
      this.updateLoginLocations();
    },
    /**
     * Watch for changes to the selected company
     * @param {Object} value 
     */
    selectedCompany(value) {
      this.getLocationsAndUsers(value);
    }
  },
  mounted() {
    /**
     * Set the local variables to the prop
     */
    this.modulesLocal = this.modules.map(x => ({...x}));
    this.addPharmacyForm.country_name = this.getCountryName;
  },
  methods: {
    /**
     * Emit the close modal event
     */
    closeModal() {
      this.$emit('closeModal');
    },
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
     getAddressData(addressData, placeResultData) {
      // Calculate the address from subpremise, street_number, and route
      let street_address = '';
      if (addressData.subpremise) {
        street_address += addressData.subpremise + '/';
      }
      if (addressData.street_number) {
        street_address += addressData.street_number + ' ';
      }
      if (addressData.route) {
        street_address += addressData.route;
      }
      this.addPharmacyForm.street_address = street_address;

      // Postal town as town for GB and locality as town for AU
      if (this.countryCode === 'GB') {
        this.addPharmacyForm.town = placeResultData.address_components.find(x => x.types.includes('postal_town')).long_name;
      }
      else if (this.countryCode === 'AU') {
        this.addPharmacyForm.town = addressData.locality;
        this.addPharmacyForm.region = addressData.administrative_area_level_1;
      }
      this.addPharmacyForm.city = addressData.administrative_area_level_2;
      this.addPharmacyForm.postcode = addressData.postal_code;
      this.addPharmacyForm.country_name = addressData.country;
      console.log('this is the address now', addressData, placeResultData, this.addPharmacyForm);
    },
    /**
     * Debounce search to retrieve companies based on company name
     * 400 ms delay on search
     * @param {String} companyName 
     */
    debounceSearchCompanies(companyName) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchCompanies(companyName);
      }, 400);
    },
    /**
     * Search for companies based on company name
     * @param {String} companyName 
     */
    async searchCompanies(companyName) {
      this.$store.dispatch('getRefreshToken');
      this.loadingCompanies = true;
      await axios.post('/api/company/search', { "page": 1, "name": companyName }, this.$store.state.header)
      .then(res => {
        this.handleSearchCompaniesSuccess(res);
      }).catch(err => {
        this.handleSearchCompaniesError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the success response for the companies
     * @param {Object} res 
     */
    handleSearchCompaniesSuccess(res) {
      console.log('The search companies response is: ', res);
      this.loadingCompanies = false;
      this.companies = res.data.results;
    },
    /**
     * Handle the error response for the companies
     * @param {Object} err 
     */
    handleSearchCompaniesError(err) {
      console.log('The search companies error is: ', err);
      this.loadingCompanies = false;
    },
    /**
     * Retrieve the users, locations, and login locations for a company
     * @param {Object} company 
     */
    async getLocationsAndUsers(company) {
      if (typeof company === 'object' && company !== null) {
        // Get users and locations before loading login locations
        await Promise.all([
          this.getUsers(company),
          this.getLocations(company),
        ]);
        await this.getLoginLocations(company)
      }
    },
    /**
     * Get the users for a company
     * @param {Object} company 
     */
    async getUsers(company) {
      if (typeof company === 'object' && company !== null) {
        this.$store.dispatch('getRefreshToken');
        this.loadingUsers = true;
        await axios.post(`/api/company/${company.uuid}/users`, { company_id: company.uuid }, this.$store.state.header)
        .then(res => {
          this.handleGetUsersSuccess(res);
        }).catch(err => {
          this.handleGetUsersError(this.handleAxiosError(err));
        });
      }
    },
    /**
     * Handle the success response for the users
     * @param {Object} res 
     */
    handleGetUsersSuccess(res) {
      console.log('The get users response is: ', res);
      this.loadingUsers = false;
      this.users = res.data.data;
    },
    /**
     * Handle the error response for the users
     * @param {Object} err 
     */
    handleGetUsersError(err) {
      console.log('The get users error is: ', err);
      this.loadingUsers = false;
    },
    /**
     * Retrieve the locations for a company
     * @param {Object} company 
     */
    async getLocations(company) {
      if (typeof company === 'object' && company !== null) {
        this.$store.dispatch('getRefreshToken');
        this.loadingLocations = true;
        await axios.post(`/api/company/${company.uuid}/locations`, null, this.$store.state.header)
        .then(res => {
          this.handleGetLocationsSuccess(res);
        }).catch(err => {
          this.handleGetLocationsError(this.handleAxiosError(err));
        });
      }
    },
    /**
     * Handle the success response for the locations
     * @param {Object} res 
     */
    handleGetLocationsSuccess(res) {
      console.log('The search locations response is: ', res);
      this.loadingLocations = false;
      this.pharmacies = res.data.data;
    },
    /**
     * Handle the error response for the locations
     * @param {Object} err 
     */
    handleGetLocationsError(err) {
      console.log('The search locations error is: ', err);
      this.loadingLocations = false;
    },
    /**
     * Create a company
     * @returns {Promise<void>}
     */
    async addCompany() {
      this.$store.dispatch('getRefreshToken');
      this.createCompanyError = '';
      this.createCompanySuccess = '';
      this.creatingCompany = true;

      await axios.post('/api/company/create', this.addCompanyPayload, this.$store.state.header)
      .then(res => {
        this.handleCreateCompanySuccess(res);
      }).catch(err => {
        this.handleCreateCompanyError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the success response for the company
     * @param {Object} res 
     */
    handleCreateCompanySuccess(res) {
      console.log('The create company response is: ', res);
      this.selectedCompany = res.data.company;
      // Set the company
      this.companies = [res.data.company];
      // Clear the form
      this.clearCompany();
      this.creatingCompany = false;
      this.createCompanySuccess = 'Company Created Successfully.'
    },
    /**
     * Handle the error response for the company
     * @param {Object} err 
     */
    handleCreateCompanyError(err) {
      console.log('The create company error is: ', err);
      this.creatingCompany = false;
      this.createCompanyError = err;
    },
    /**
     * Create a pharmacy
     * @returns {Promise<void>}
     */
    async addPharmacy() {
      this.$store.dispatch('getRefreshToken');
      this.createPharmacyError = '';
      this.createPharmacySuccess = '';
      this.creatingPharmacy = true;

      await axios.post('/api/create-company-pharmacy', this.addPharmacyPayload, this.$store.state.header)
      .then(res => {
        this.handleCreatePharmacySuccess(res);
      }).catch(err => {
        this.handleCreatePharmacyError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the success response for the pharmacy
     * @param {Object} res 
     */
    handleCreatePharmacySuccess(res) {
      console.log('The create pharmacy response is: ', res);
      this.creatingPharmacy = false;
      // Clear the form
      this.clearPharmacy();
      this.createPharmacySuccess = 'Pharmacy Created Successfully.';
      // Update the pharmacies
      this.pharmacies.push(res.data.location);
      this.pharmacies = [...this.pharmacies]; // Clone the array so the watcher is triggered
      this.getLoginLocations(this.selectedCompany);
    },
    /**
     * Handle the error response for the pharmacy
     * @param {Object} err 
     */
    handleCreatePharmacyError(err) {
      console.log('The create pharmacy error is: ', err);
      this.creatingPharmacy = false;
      this.createPharmacyError = err;
    },
    /**
     * Create a user
     * @returns {Promise<void>}
     */
    async addUser() {
      this.$store.dispatch('getRefreshToken');
      this.createUserError = '';
      this.createUserSuccess = '';
      this.creatingUser = true;

      await axios.post('/api/user/create', this.addUserPayload, this.$store.state.header)
      .then(res => {
        this.handleCreateUserSuccess(res);
      }).catch(err => {
        this.handleCreateUserError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the success response for the user
     * @param {Object} res 
     */
    handleCreateUserSuccess(res) {
      console.log('The create user response is: ', res);
      this.creatingUser = false;
      // Clear the form
      this.clearUser();
      this.createUserSuccess = 'User Created Successfully.'
      // Update the users
      this.users.push(res.data.data);
      this.users = [...this.users]; // Clone the array so the watcher is triggered
      this.getLoginLocations(this.selectedCompany);
    },
    /**
     * Handle the error response for the user
     * @param {Object} err 
     */
    handleCreateUserError(err) {
      console.log('The create user error is: ', err);
      this.creatingUser = false;
      this.createUserError = err;
    },
    /**
     * Get the login locations for a company
     * @param {Object} company 
     */
    async getLoginLocations(company) {
      this.$store.dispatch('getRefreshToken');
      this.loadingLoginLocations = true;

      await axios.post(`/api/company/${company.uuid}/login-locations`, null, this.$store.state.header)
      .then(res => {
        this.handleGetLoginLocationsSuccess(res);
        this.getOnboardingNotifications(company);
      }).catch(err => {
        this.handleGetLoginLocationsError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the success response for the login locations
     * @param {Object} res 
     */
    handleGetLoginLocationsSuccess(res) {
      console.log('The get login locations response is: ', res);
      this.loadingLoginLocations = false;
      // Loop through each of the users and check if they are in the selectedLoginLocations
      // Add each of the pharmacies to the selectedLoginLocations if they are not already there
      res.data.data.forEach(login => {
        if (!this.selectedLoginLocations[login.uuid]) {
          return;
        }
        login.locations.forEach(pharmacy => {
          if (!this.selectedLoginLocations[login.uuid].includes(pharmacy.uuid)) {
            this.selectedLoginLocations[login.uuid].push(pharmacy.uuid);
          }
        });
      });
    },
    /**
     * Handle the error response for the login locations
     * @param {Object} err 
     */
    handleGetLoginLocationsError(err) {
      console.log('The get login locations error is: ', err);
      this.loadingLoginLocations = false;
    },
    /**
     * Retrieve the onboarding notification status for all login locations in the company
     * @param {Object} company 
     */
    async getOnboardingNotifications(company) {
      this.$store.dispatch('getRefreshToken');
      this.loadingOnboardingNotifications = true;
      this.onboardingNotifications = [];

      await axios.post(`/api/company/${company.uuid}/strongpro-onboarding-notifications`, null, this.$store.state.header)
      .then(res => {
        this.handleGetOnboardingNotificationsSuccess(res);
      }).catch(err => {
        this.handleGetOnboardingNotificationsError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the success response for the onboarding notifications
     * @param {Object} res 
     */
    handleGetOnboardingNotificationsSuccess(res) {
      console.log('The get onboarding notifications response is: ', res);
      this.loadingOnboardingNotifications = false;

      // Loop through each of the login locations, set selected to true if destination is null
      res.data.data.forEach(login_location => {
        if (login_location.created_at === null) {
          login_location.selected = true;
        } else {
          login_location.selected = false;
        }
      });

      this.onboardingNotifications = res.data.data;
      this.onboardingNotificationsSuccess = 'Onboarding Notifications Retrieved Successfully';
    },
    /**
     * Handle the error response for the onboarding notifications
     * @param {Object} err 
     */
    handleGetOnboardingNotificationsError(err) {
      console.log('The get onboarding notifications error is: ', err);
      this.loadingOnboardingNotifications = false;
      this.onboardingNotificationsError = err;
    },
    /**
     * Update the login locations array
     */
    updateLoginLocations() {
      this.loginLocations = [...this.users];
      // Create an associative array of user uuid => array of pharmacy uuids
      this.selectedLoginLocations = Object.assign(...this.users.map((user) => ({ [user.uuid]: [] })));
    },
    /**
     * Link all login locations
     */
    linkAllLoginLocations() {
      // Create an associative array of user uuid => array of pharmacy uuids
      this.selectedLoginLocations = Object.assign(...this.users.map((user) => ({ [user.uuid]: [] })));
      // Loop through each of the users and set the selected login locations to all of the pharmacies
      Object.keys(this.selectedLoginLocations).forEach(key => {
        this.selectedLoginLocations[key] = this.pharmacies.map(pharmacy => pharmacy.uuid);
      });
    },
    /**
     * Unlink all login locations
     */
    unlinkAllLoginLocations() {
      // Create an associative array of user uuid => array of pharmacy uuids
      this.selectedLoginLocations = Object.assign(...this.users.map((user) => ({ [user.uuid]: [] })));
    },
    /**
     * Link the locations to the users
     * @returns {Promise<void>}
     */
    async linkLocations() {
      this.linkingLocations = true;
      this.linkLocationsError = '';
      this.linkLocationsSuccess = '';
      let promises = [];
      // Loop throgh each of the logins and pharmacies
      Object.keys(this.selectedLoginLocations).forEach(login => {
        // Create a query to sync the locations for the login and company
        promises.push(axios.post(`/api/user/${login}/sync-company-locations`, { company_id: this.selectedCompany.uuid, location_ids: this.selectedLoginLocations[login] }, this.$store.state.header));
      });

      // Wait until all the requests have settled
      Promise.allSettled(promises)
      .then(res => {
        let responseMessage = '';
        // Loop through each of the promise responses
        res.forEach(response => {
          // If the response was rejected, update the response message
          if (response.status === 'rejected') {
            responseMessage += " " + this.handleAxiosError(response.reason);
          }
        });
        // If any of the responses were rejected, handle the error
        if (responseMessage !== '') {
          this.handleLinkLocationsError(responseMessage);
        }
        // If all the responses were successful, handle the success
        else {
          this.handleLinkLocationsSuccess(res);
        }
      }).catch(err => {
        this.handleLinkLocationsError(this.handleExiosError(err));
      });
    },
    /**
     * Handle the success response for the location
     * @param {Object} res 
     */
    handleLinkLocationsSuccess(res) {
      console.log('The link location response is: ', res);
      this.linkingLocations = false;
      this.linkLocationsSuccess = 'Locations Linked Successfully';
    },
    /**
     * Handle the error response for the location
     * @param {Object} err 
     */
    handleLinkLocationsError(err) {
      console.log('The link location error is: ', err);
      this.linkLocationsError = err;
      this.linkingLocations = false;
    },
    /**
     * Send the strongpro welcome email
     * @returns {Promise<void>}
     */
    async sendStrongProWelcomeEmail() {
      this.$store.dispatch('getRefreshToken');
      this.sendingWelcomeEmail = true;
      this.sendWelcomeEmailError = '';
      this.sendWelcomeEmailSuccess = '';

      await axios.post(`/api/send-strongpro-welcome-email`, this.strongProWelcomeEmailPayload, this.$store.state.header)
      .then(res => {
        this.handleSendWelcomeEmailSuccess(res);
      }).catch(err => {
        this.handleSendWelcomeEmailError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the success response for the welcome email
     * @param {Object} res 
     */
    handleSendWelcomeEmailSuccess(res) {
      console.log('The send welcome email response is: ', res);
      this.sendingWelcomeEmail = false;
      this.sendWelcomeEmailSuccess = 'Welcome Email Sent Successfully';
      this.getOnboardingNotifications(this.selectedCompany);
    },
    /**
     * Handle the error response for the welcome email
     * @param {Object} err 
     */
    handleSendWelcomeEmailError(err) {
      console.log('The send welcome email error is: ', err);
      this.sendingWelcomeEmail = false;
      this.sendWelcomeEmailError = err;
    },
    /**
     * Select all onboardingNotifications for sending
     */
    selectAllWelcomeEmail() {
      this.onboardingNotifications.forEach(notification => {
        notification.selected = true;
      });
    },
    /**
     * Select all unsent onboardingNotifications for sending
     */
    selectUnsentWelcomeEmail() {
      this.onboardingNotifications.forEach(notification => {
        if (notification.created_at === null) {
          notification.selected = true;
        } else {
          notification.selected = false;
        }
      });
    },
    /**
     * Deselect all onboardingNotifications for sending
     */
    deselectAllWelcomeEmail() {
      this.onboardingNotifications.forEach(notification => {
        notification.selected = false;
      });
    },
    /**
     * Get the full name of a user from their uuid
     * @param {String} uuid
     */
    fullNameFromUuid(uuid) {
      let user = this.users.find(user => user.uuid === uuid);
      if (user) {
        return [user.person.personal_information.first_name, user.person.personal_information.middle_name, user.person.personal_information.last_name].filter(Boolean).join(' ');
      }
      return '';
    },
    /**
     * Clear the company data.
     */
    clearCompany() {
      this.addCompanyPayload.company_name = '';
      this.addCompanyPayload.username = '';
      this.addCompanyPayload.email = '';
      this.addCompanyPayload.first_name = '';
      this.addCompanyPayload.last_name = '';
      this.addCompanyPayload.password = '';
      this.addCompanyPayload.sixty_day_signup = false;
      this.addCompanyPayload.cloud_ecdr = false;
    },
    /**
     * Clear the pharmacy data.
     */
    clearPharmacy() {
      this.addPharmacyForm.name = '';
      this.addPharmacyForm.street_address = '';
      this.addPharmacyForm.city = '';
      this.addPharmacyForm.region = '';
      this.addPharmacyForm.postcode = '';
      this.addPharmacyForm.town = '';
      this.addPharmacyForm.data_source = '';
      this.addPharmacyForm.no_guild_consent = false;
      this.addPharmacyForm.owner = '';
      this.selectedModules = [];
    },
    /**
     * Clear the user data.
     */
    clearUser() {
      this.addUserForm.username = '',
      this.addUserForm.password = '',
      this.addUserForm.first_name = '',
      this.addUserForm.last_name = '',
      this.addUserForm.email = '',
      this.addUserForm.account_creation_email = true
    }
  }
}
</script>
  
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

:deep(.p-accordion-content) {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.modal-container {
  display: flex;
  flex-direction: column;
  height: 78vh;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-item {
  flex: 1;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pharmacies-container {
  display: flex;
  border-radius: 5px;
  background-color: $grey;
  overflow-y: auto;
  flex: 1 1 150px;
  row-gap: 5px;
  column-gap: 10px;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 10px 0 10px;
}

.pharmacy-bubble {
  display: flex;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  background-color: $bluegreen;
  color: white;
  font-weight: 500;
  font-size: 11pt;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.close-icon {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 0;
}

</style>
