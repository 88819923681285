<template>
  <div
    class="view"
    style="height: fit-content; background-position: top center; background-repeat: no-repeat; background-size: cover; background-image: linear-gradient(to bottom, #FEFFFF 0%, 32.6605498790741%, #B3CEE2 65.3210997581482%, 82.6605498790741%, #8DE3DA 100%);"
  >
    <!-- <div class="header">
      <div class="container flex-row">
        <div>
          <a
            href="https://strongroom.ai/"
            target="_blank"
          >
            <img
              class="header-logo"
              :src="lockupLogoSrc"
              alt="StrongRoom AI Logo"
            >
          </a>
        </div>
      </div>
    </div> -->
    <div v-if="loadingUnsubscribeForm">
      <ProgressSpinner
        class="medium-icon"
        stroke-width="8"
        animation-duration=".75"
        aria-label="Loading..."
      />
      <p>Loading...</p>
    </div>
    <div v-else-if="unsubscribeValidationError!==''">
      <p>Invalid URL</p>
    </div>
    <div
      v-else 
      class="container main"
    >
      <h1
        class="form-header trial-header"
      >
        Unsubscribe from Pharmacy Email Communications
      </h1>
      <hr style="width:100%;text-align:left;margin-left:0; height: 5px; background-color: #008491; border: none; margin-top: 20px;">
      <div>
        <h3>{{ location.name }}</h3>
        <p>{{ location.street_address + ", " + location.town + ", " }}{{ countryCode == 'AU' ? location.region : '' }}{{ location.postcode }}</p>
        <p>{{ location.phone_number }}</p>
      </div>
      <div style="display: flex; align-items: center; margin-top: 20px; gap: 20px; flex-wrap: wrap">
        <p>Powered By </p>
        <a
          href="https://www.strongroom.ai/"
          target="_blank"
        >
          <img
            style="width: 220px; height: auto;"
            :src="strongProLogoSrc"
            alt="Strong Pro Logo"
          >
        </a>
      </div>
      
      <Transition name="fade">
        <div
          v-if="optOutStatus"
          class="section"
        >
          <div class="main-para">
            <p>Thank you for checking your preferences.</p>
            <p>You have already unsubscribed from email communications.</p>
            <p>If you wish to update your preferences in the future, please contact {{ location.name }}.</p>
          </div>
        </div>
        <div v-else-if="!unsubscribed">
          <!-- DESCRIPTION SECTION -->
          <section
            class="section"
            style="padding-top: 0;"
          >
            <div class="main-para">
            </div>
          </section>
          <!-- FORM SECTION -->
          <section 
            class="section grey-section" 
            style="margin: 0; border-top-left-radius: 0px; border-top-right-radius: 0px;"
          >
            <div class="signup-container">
              <div class="form-container signup-container__form">
                <div class="form-row two-col">
                </div>
                <VueRecaptcha
                  class="two-col captcha"
                  :sitekey="siteKey"
                  @verify="verifyCaptcha"
                  @expired="expiredCaptcha"
                  @render="renderCaptcha"
                  @error="captchaError"
                />
                <p 
                  class="two-col"
                >
                  I agree to unsubscribe from this pharmacy's email communications, including prescription reminders, clinical trial invitations and marketing promotions.
                </p>
                <Button
                  v-if="!loading"
                  class="purple-button two-col"
                  label="Unsubscribe"
                  :disabled="!captcha_verified"
                  @click="unsubscribe"
                />
                <div
                  v-else
                  class="flex"
                >
                  <ProgressSpinner
                    class="medium-icon"
                    stroke-width="8"
                    animation-duration=".75"
                    fill="white"
                    aria-label="Loading..."
                  />
                  Loading...
                </div>
              </div>
            </div>
            <p
              v-if="unsubscribeError"
              class="error-message"
            >
              {{ unsubscribeError }}
            </p>
            <p
              v-if="unsubscribeSuccess"
              class="success-message"
            >
              {{ unsubscribeSuccess }}
            </p>
          </section>
        </div>
        <section
          v-else
          class="section"
        >
          <div class="main-para">
            <p>Thank you for updating your preferences.</p>
            <p>You have successfully unsubscribed from email communications.</p>
            <p>We're sorry to see you go. If you wish to update your preferences in the future, please contact {{ location.name }}. </p>
          </div>
        </section>
      </Transition>
    </div>
  </div>
  <footer>
    <div class="footer-content grey-section">
      <div class="footer-logos">
        <div class="footer-logo">
          <img
            :src="lockupLogoSrc"
            alt="StrongRoom AI Logo"
          >
          <div class="footer-tnc">
            <p>
              <a
                href="https://strongroom.ai/privacy/"
                target="_blank"
                style="color: black; text-decoration: none;"
              >Privacy Policy</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <hr
        style="margin-right: -10px; width: 2px; background-color: grey; border: none; height: 50px; margin-top: 3px;"
        class="signup-container__vertline"
      > -->
  </footer>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import axios from 'axios';

export default {
  name: 'UnsubscribeEmailPage',
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      lockupLogoSrc: require('@/assets/logos/Lockup_Colour_Black.svg'),
      lockupLogoWhiteSrc: require('@/assets/logos/Lockup_Colour_White.svg'),
      isoLogoSrc: require('@/assets/logos/iso_27001.png'),
      hipaaLogoSrc: require('@/assets/logos/hipaa.png'),
      aesLogoSrc: require('@/assets/logos/aes_256.png'),
      strongProLogoSrc: require('@/assets/logos/StrongPro_logo.svg'),
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      loadingUnsubscribeForm: true,
      unsubscribeValidationError: '',
      captcha_verified: '',
      unsubscribed: false,
      unsubscribeError: '',
      unsubscribeSuccess: '',
      loading: false,
      notificationId: null,
      optOutStatus: false,
      location: {
        name: '',
        phone_number: '',
        street_address: '',
        town: '',
        region: '',
        postcode: ''
      },
    }
  },
  computed: {
  },
  async mounted() {
    if (this.$userback) {
      this.$userback.hide();
    }
    await this.getUnsubscribeForm();
  },
  methods: {
    
    async getUnsubscribeForm() {
      this.loadingUnsubscribeForm = true;

      await axios.post('/api/validate-unsubscribe/'+this.$route.params.id, {})
      .then((res) => {
        this.handleUnsubscribeFormSuccess(res);
      })
      .catch((error) => {
        this.handleUnsubscribeFormError(this.handleAxiosError(error));
      });
    },
    /**
     * Handle the success response for unsubscribe form
     */
     handleUnsubscribeFormSuccess(res) {
      console.log(res.data.data);
      this.loadingUnsubscribeForm = false;
      this.notificationId = res.data.data.notification_id;
      this.optOutStatus = res.data.data.opt_out_status;
      this.location = res.data.data.location;

    },
    /**
     * Handle the error response for unsubscribe form
     * @param {Object} err
     */
     handleUnsubscribeFormError(err) {
      this.unsubscribeValidationError = err;
      this.loadingUnsubscribeForm = false;
      console.log('The unsubscribe form error is: ', err);
    },
    /**
     * Unsubscribe from the email notification
     * @returns {Promise<void>}
     */
    async unsubscribe() {
      this.unsubscribeError = '';
      this.unsubscribeSuccess = '';
      this.loading = true;

      await axios.post(`/api/unsubscribe/${this.notificationId}`, {})
      .then((response) => {
        this.handleUnsubscribeSuccess(response);
      })
      .catch((error) => {
        this.handleUnsubscribeError(this.handleAxiosError(error));
      });
    },
    /**
     * Handle the success response for unsubscribe
     * @param {Object} res
     */
     handleUnsubscribeSuccess(res) {
      console.log('The unsubscribe response is: ', res);
      this.loading = false;
      this.unsubscribed = true;
    },
    /**
     * Handle the error response for unsubscribe
     * @param {Object} err
     */
     handleUnsubscribeError(err) {
      console.log('The unsubscribe error is: ', err);
      this.loading = false;
      this.unsubscribeError = err;
    },
    verifyCaptcha(response) {
      console.log("Captcha verified: ", response);
      this.captcha_verified = response;
    },
    expiredCaptcha() {
      console.log("Captcha expired");
      this.captcha_verified = '';
    },
    renderCaptcha(id) {
      console.log("Captcha rendered: ", id);
    },
    captchaError() {
      console.log("Captcha error");
      this.captcha_verified = '';
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/main.scss";

p {
  font-size: 1.25rem;
}

.view {
  justify-content: center;
}

.flex-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.flex-row > a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  border-bottom: 1px solid grey;
  position: relative;
  width: 100%;
}

.form-header {
    padding-top: 2rem;
}

.trial-header {
  color: #00205B; 
  font-size: 2em; 
  word-wrap: break-word
}

.container {
  width: 100%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.header > .container {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  height: 5rem;
}

.header-logo {
  width: 12rem;
  height: 100%;
}

.black-button a {
  text-decoration: none;
  color: inherit;
}

.main {
  padding-bottom: 2rem;
}

.section {
  padding-top: 2rem;
}
.grey-section{
  background-color: #f3f4f4;
  padding: 32px;
  border-radius: 6px;
}

.main-para {
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;

  ol {
    margin-top: 0px;
  }
}

.important-text{
  display: grid;
  grid-template-columns: 20px 1fr;
  margin-top: 30px;
  background-color: #f5f5f5;
  border-radius: 6px;
}

.important-text-content{
  padding: 29px;
}

.sign-up-container{
  color: white;
  background-color: #101820;
  padding: 32px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.purple-border{
  background-color: #6930A7;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.flex-expand {
  flex: 1;
}

.flex-icons {
  display: grid;
  grid-template-columns: [first] 150px [line2] auto;
  gap: 1rem;
  p {
    font-size: 15px;
  }
}

.app-logos {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 15px 0;
  gap: 15px;
}

.logo-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  justify-self: flex-start;
  flex: 1;
  max-width: 270px;
  text-align: left;
  gap: 0.5rem;

  img {
    max-height: 52px;
    min-height: 52px;
    align-self: flex-start;
    flex: 0 0 auto;
  }
}

.engagement-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 15px;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.grey-button {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.image-div {
  width: 150px;
  text-align: center;
}

.cert-image {
  max-height: 100px;
  max-width: 150px;
  width: auto;
  height: auto;
}

.cert-item {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  p {
    margin: 0;
  }
}

.signup-container {
  height: 100%;
}

.signup-container__form {
  grid-area: form;
}

.signup-container__vertline {
  grid-area: vertline;
}

.signup-container__security {
  grid-area: security;
}

.left-col {
  grid-column-start: 1;
  grid-column-end: 2;
}

.right-col {
  grid-column-start: 4;
  grid-column-end: 5;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-container {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 20px;
}

.flex {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}
.two-col {
  grid-area: span 1 / span 2;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.p-progress-spinner {
  margin: 0 0 0 0 !important;
}

.flex-right {
  margin-left: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.purple-button{
  background-color:#6930A7;
  color: white;
  font-weight: bold;
  border: none;
}

footer {
  background-color: #f3f4f4;
  color: white;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}

.footer-content {
  max-width: 1150px;
  margin: 0 auto;
  padding: 51px 0;

  p {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: left;
  }
}

.footer-misc-text {
  display: flex;
  flex-direction: column;

  b {
    font-size: 1rem;
    text-align: left;
    color: $deepgrey;
    font-weight: 600;
  }

  p {
    margin-top: 0;
    font-size: 0.75rem;
    text-align: left;
    color: $deepgrey;
    font-weight: 500;
  }
}

.footer-logos {
  display: flex;
  gap: 50px;
  max-width: 800px;
}

.footer-logo {
  flex: 1;

  img {
    display: flex;
    max-width: 300px;
    height: 80px;
  }
}

.captcha {
  margin: 0; 
  display: table;
}

.footer-tnc {
  display: flex;
  justify-content: flex-start;
  max-width: 400px;
  align-items: center;
}

@media (max-width: 420px) {
  .captcha {
    transform:scale(0.9);
    -webkit-transform:scale(0.9);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
  }

  .trial-header {
    font-size: 1.8em;
  }
}

@media (max-width: 390px) {
  .captcha {
    transform:scale(0.7);
    -webkit-transform:scale(0.7);
  }

  .trial-header {
    font-size: 1.5em;
  }
}

@media (max-width: 330px) {
  .captcha {
    transform:scale(0.6);
    -webkit-transform:scale(0.6);
  }
  .footer-logos {
    gap: 20px;
  }

  .trial-header {
    font-size: 1.3em;
  }
}

@media (max-width: 300px) {
  .captcha {
    transform:scale(0.53);
    -webkit-transform:scale(0.53);
  }
  .footer-logos {
    gap: 20px;
  }
}


@media (min-width: 768px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1048px) {
  .signup-container {
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    height: 100%;
    grid-template-areas: 'form vertline security';
    grid-template-columns: minmax(500px, 1fr);
  }
}

@media (max-width: 1048px) {
  .signup-container__vertline {
    display: none;
  }
}

@media (min-width: 1320px) {
  .container {
    width: 1240px;
  }
}
</style>
