<template>
  <div class="login-view">
    <div class="login-form">
      <img
        :src="srIconLogoSrc"
        alt="StrongRoom AI Icon"
        style="height:70px; object-fit: contain; align-self: flex-start;"
      >
      <div class="login-form-details">
        <h2>
          Login
        </h2>
        <Transition
          name="fade"
          appear
        />
        <InputText
          v-model="loginForm.name"
          placeholder="Username/email"
        />
        <InputText
          v-if="!forgotPassword"
          v-model="loginForm.password"
          placeholder="Password"
          type="password"
          style="position: relative;"
          autocomplete="one-time-code"
          @keyup.enter="submitLogin()"
        />
        <p
          v-if="userError"
          class="form-error"
        >
          {{ userError }}
        </p>
        <div class="p-field-checkbox">
          <Checkbox
            v-model="rememberUsername"
            name="username-binary"
            :binary="true"
          />
          <label
            for="username-binary"
            style="margin-left: 10px;"
          >Remember username</label>
        </div>
        <div
          v-if="!forgotPassword"
          style="margin-top: 1em; display: grid;  width: 100%"
        >
          <p
            name="login-button"
            class="flat-light-button"
            @click="loadingLogin ? '' : submitLogin()"
          >
            {{ loadingLogin ? 'Processing...': 'Login' }}
          </p>
          <p
            style="border-bottom: 2px solid black; font-weight: 1em; cursor: pointer; width: fit-content; justify-self: flex-end"
            @click="forgotPassword = true"
          >
            Forgot Password?
          </p>
        </div>
        <!-- FORGOT PASSWORD -->
        <div
          v-if="forgotPassword"
          style="margin-top: 1em; display: flex; align-items: center; justify-content: space-between;"
        >
          <p
            class="flat-light-button"
            style="height: 30px;"
            @click="submitForgotPassword()"
          >
            Email Reset Password
          </p>
          <p
            class="flat-light-button"
            style="height: 30px;"
            @click="forgotPassword = false"
          >
            Cancel
          </p>
        </div>
        <div style="display: flex; flex-wrap: wrap; width: 24em">
          <p
            v-if="loginError"
            style="color: red; word-break:break-word"
          >
            Warning: {{ loginError }}
          </p>
          <ProgressSpinner
            v-if="loadingPasswordReset"
            class="medium-icon"
            stroke-width="8"
            animation-duration=".75"
            fill="white"
          />
          <p
            v-if="loginMessage"
            style="word-break: break-word; font-weight: bold; text-align: left;"
          >
            {{ loginMessage }}
          </p>
        </div>
          
        <p
          v-if="loginCount"
          style="color: red; font-weight: bold;"
        >
          {{ 5 - loginCount }} attempts remaining
        </p>
      </div>
    </div>
    <div class="login-context">
      <!-- STRONGROOM AI LOGO -->
      <img
        :src="fullLogoSrc"
        alt="StrongRoom AI Logo"
        class="login-logo"
      >
      <!-- CONTACT DETAILS -->
      <div class="contact-links">
        <a
          class="contact-item"
          href="tel:+61370654066"
        >
          <img
            :src="phoneIconSrc"
            alt="Phone Contact"
            class="medium-icon"
          >
          <p>
            (03) 7065 4066
          </p>
        </a>
        <a
          class="contact-item"
          href="mailto:support@strongroom.ai"
        >
          <img
            :src="emailIconSrc"
            alt="Email Contact"
            class="medium-icon"
          >
          <p>
            support@strongroom.ai
          </p>
        </a>
        <a
          class="contact-item"
          href="https://www.strongroom.ai"
          target="_blank"
        >
          <img
            :src="websiteIconSrc"
            alt="Website Contact"
            class="medium-icon"
          >
          <p>
            www.strongroom.ai
          </p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  components: {
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  data() {
    return {
      fullLogoSrc: require('@/assets/logos/full_logo.svg'),
      phoneIconSrc: require('@/assets/icons/phone_icon.svg'),
      emailIconSrc: require('@/assets/icons/email_icon.svg'),
      websiteIconSrc: require('@/assets/icons/website_icon.svg'),
      srIconLogoSrc: require('@/assets/logos/short_logo_white.svg'),
      // Login Data
      loginCount: 0,
      loginError: '',
      loginMessage: '',
      loadingLogin: false,
      loginForm: {
        name: '',
        password: ''
      },
      loadingPasswordReset: false,
      forgotPassword: false,
      rememberUsername: '',
      userError: '',
      access_token: '',
      selectLocation: false,
      locationSelected: '',
      locationResults: [],
    };
  },
  watch: {
    rememberUsername: function() {
      localStorage.setItem('rememberUsername', this.rememberUsername);
    }
  },
  mounted() {
    // Set subdomain
    if (window.location.host.split('.')[0] == 'staging') {
      this.$store.commit('setSubdomain', 'staging')
    } else if(window.location.host.split('.')[0] == 'care'){
      this.$store.commit('setSubdomain', 'prod')
    } else{
      this.$store.commit('setSubdomain', 'local')
    }

    // Set rememberUsername to true if not in localStorage
    if (!localStorage.getItem('rememberUsername')) {
      console.log('rememberUsername not in localStorage, setting to true.');
      this.rememberUsername = true;
    }
    // Set username if rememberUsername is true
    else if (localStorage.getItem('rememberUsername') == 'true') {
      console.log('rememberUsername is true, setting username.');
      this.rememberUsername = Boolean(localStorage.getItem('rememberUsername'));
      if (localStorage.getItem('username')) {
        this.loginForm.name = localStorage.getItem('username');
      }
    }

    // Clear username if rememberUsername is false
    else {
      console.log('rememberUsername is not true, setting to false.')
      this.rememberUsername = false;
      localStorage.removeItem('username');
    }

    localStorage.removeItem('currentLocation');
    localStorage.removeItem('user');
    localStorage.removeItem('session_id');
    localStorage.removeItem('lastRefresh');
    localStorage.removeItem('savedform');
    localStorage.removeItem('sr_login');
    localStorage.removeItem('guild_login');
    localStorage.removeItem('clinical_trials_login');
    localStorage.removeItem('recruiter');
    localStorage.removeItem('sixty_day_signup');
    localStorage.removeItem('data_analytics');
    localStorage.removeItem('supplier_analytics');
    localStorage.removeItem('ad_analytics');
    localStorage.removeItem('ecdr');
    localStorage.removeItem('portal_eula');
    document.cookie = "auth=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    this.$store.commit('setAuthorization');

    // Remove the user in Splunk for RUM
    this.setSplunkUserSession(undefined);
  },
  methods: {
    cacheUsername() {
      if (this.rememberUsername) {
        localStorage.setItem('username', this.loginForm.name);
      } else {
        localStorage.removeItem('username');
      }
    },
    // Set temp token expiry
    setExpiry() {
      let now = new Date();
      let time = now.getTime();
      let expiredTime = time + 60;
      let setTime = now.setTime(expiredTime);
      console.log('now is', setTime);
      document.cookie = "auth=" + "authtoken2342342" + "; max-age=" + 60 + "; path=/";
      console.log('document.cookie is', document.cookie);
    },
    // Submit the user login to the application
    async submitLogin() {
      this.loginError = '';
      this.loadingLogin = true;
      if (!this.loginForm.name.trim() || !this.loginForm.password.trim()) {
        this.userError = 'Username and password are required.';
        this.loadingLogin = false;
        return;
      }

      this.userError = '';
      this.setExpiry();

      try {
        // Call the Enterprise Login endpoint
        const res = await axios.post('/api/enterprise-login', this.loginForm);
        this.handleLoginSuccess(res.data);
      } catch (err) {
        this.handleLoginError(err);
      }
    },
    handleLoginSuccess(data) {
      // Reset the login count and set access_token
      this.loadingLogin = false;
      localStorage.removeItem('loginCount');
      this.access_token = data.access_token;

      // Set the cookie
      let cookie = "auth=" + data.access_token + "; max-age=" + 3600 + "; path=/; samesite";
      document.cookie = cookie;
      console.log('document.cookie is', document.cookie);
      this.$store.commit('setAuthorization');
      this.$store.commit('setUser', data);
      this.$store.commit('setRecruiter', data.recruiter);
      localStorage.setItem('user', JSON.stringify(this.$store.state.user));
      localStorage.setItem('sr_login', data.sr_login);
      localStorage.setItem('guild_login', data.guild_login);
      localStorage.setItem('clinical_trials_login', data.clinical_trials_login);
      localStorage.setItem('recruiter', JSON.stringify(this.$store.state.recruiter));
      localStorage.setItem('sixty_day_signup', data.sixty_day_signup);
      localStorage.setItem('data_analytics', data.data_analytics);
      localStorage.setItem('supplier_analytics', data.supplier_analytics);
      localStorage.setItem('ad_analytics', data.ad_analytics);
      localStorage.setItem('ecdr', data.ecdr);
      localStorage.setItem('lastRefresh', JSON.stringify(new Date().getTime()));
      if (data.portal_eula) {
        localStorage.setItem('portal_eula', data.portal_eula);
      }

      // Set the user in Splunk for RUM
      this.setSplunkUserSession(data.session_id);

      // Start the time runner
      this.$store.dispatch('timeRunner');
      this.cacheUsername();

      // Redirect to appropriate page
      if (data.data_analytics) {
        this.$router.push('/financials');
      } else if (data.ecdr) {
        this.$router.push('/s8management');
      } else if (data.sixty_day_signup) {
        this.$router.push('/sixtydd');
      } else if (data.guild_login) {
        this.$router.push('/guildsixtydd');
      } else if (data.clinical_trials_login) {
        this.$router.push('/referrals');
      }
      else if (data.sr_login) {
        this.$router.push('/settings');
      }
    },
    handleLoginError(err) {
      console.log('enterprise login err is', err);
      this.loadingLogin = false;
      if (err.message.split(" ").includes('403')) {
        this.loginError = err.response && err.response.data ? err.response.data : "Incorrect username or password.";
        this.loginCount = localStorage.getItem('loginCount') ? Number(JSON.parse(localStorage.getItem('loginCount'))) + 1 : 1;
        localStorage.setItem('loginCount', this.loginCount);
      } else {
        if (err.response && err.response.data) {
          this.loginError = err.response.data;
        } else {
          this.loginError = err.response;
        }
      }
    },
    /**
     * Reset the user's password
     */
    async submitForgotPassword() {
      this.loadingPasswordReset = true;
      this.loginMessage = 'Submitting Reset Request...';
      console.log('Submitting forgot password');

      axios.post('/api/user/request-reset-password', { username: this.loginForm.name })
      .then(res => {
        this.handleForgotPasswordSuccess(res);
      }).catch(err => {
        this.handleForgotPasswordError(err);
      });
    },
    /**
     * Handle the success of the forgot password request
     * @param {Object} res
     */
    handleForgotPasswordSuccess(res) {
      console.log('Forgot password success', res);
      this.loadingPasswordReset = false;
      this.loginMessage = 'If the username you entered exists, you will receive an email with a link to reset your password.';
    },
    /**
     * Handle the error of the forgot password request
     * @param {Object} err
     */
    handleForgotPasswordError(err) {
      console.log('Forgot password error', err);
      this.loadingPasswordReset = false;
      this.loginMessage = err;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-view {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
}

.login-context {
  background-color: white;
  text-align: left;
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 3.5em;
    font-weight: bold;
  }
}

.login-logo {
  max-width: 700px;
  min-width: 280px;
  align-self: center;
  flex: 1;
}

.contact-links {
  display: flex;
  row-gap: 10px;
  column-gap: 50px;
  padding: 0px 0 50px 50px;
  flex-wrap: wrap;
}

.contact-item {
  display: flex;
  gap: 15px;
  align-items: center;
  align-self: flex-end;
  text-decoration: none;
  font-weight: 600;
  color: $strongblue;
  flex-wrap: nowrap;

  p {
    white-space: nowrap;
  }
}

.contact-item:link {
  text-decoration: none;
}

.contact-item:visited {
  text-decoration: none;
}

.contact-item:hover {
  text-decoration: underline;
}

.contact-item:active {
  text-decoration: underline;
}

.login-form {
  padding: 4em 4em;
  overflow: hidden;
  border-bottom-right-radius: 4em;
  background-color: $strongblue;
  z-index: 1;
  box-shadow: 10px 10px 25px 5px rgba(0, 0, 0, 0.1);
  height: 100%;
  flex: 1 0 350px;
  display: flex;
  flex-direction: column;
}

.login-form-details {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h2 {
    text-align: left;
    color: white;
    margin: 0;
  }

  input {
    height: 50px;
    border-radius: 5px;
  }
}

.p-field-checkbox {
  text-align: left;
}

.p-field-checkbox label {
  color: white !important;
}

.login-form-fields {
    display: grid;
}

.form-error {
    margin: 0;
    margin-bottom: 1em;
    color: $error;
    font-weight: 500;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
